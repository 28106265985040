.spinner-container {
  position: relative;
  width: 160px;
  height: 160px;
}

.main-wand {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #5db9d1; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
}

.stars1 {
  fill: #dd7f36;
  animation: flicker 1s linear infinite;
}

.stars2 {
  fill: #dd7f36;
  animation: flicker 2s linear infinite;
}

.stars3 {
  fill: #dd7f36;
  animation: flicker 3s linear infinite;
}

.stars4 {
  fill: #dd7f36;
  animation: flicker 4s linear infinite;
}

.wandfill {
  fill: #7fcde0;
}

.wand {
  fill: #5ebad2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flicker {
  0%,
  30%,
  22%,
  62%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
  }
  20%,
  22%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.5;
  }
}
