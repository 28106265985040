@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');
  
  body{
    font-family: 'Plus Jakarta Sans', Helvetica !important;
    
  }

.body-17{
    font-family: 'Plus Jakarta Sans', Helvetica !important;
    background-color: white !important;
    
  }

  .body-17-1{
    font-family: 'Plus Jakarta Sans', Helvetica !important;
    background-color: #F9FBFC !important;
    color: black;
    
    
  }


  .signinimg{
    width: 100%;
  }

  .year{
    margin-top: -22px;
    margin-right: 17px;
    width: 100%; 
  }

  
  .custom-checkbox {
    border: 2px solid black; /* Border color for unchecked state */
    background-color: #F9FBFC; /* Background color */
  }
  
  .test{
    border: 1px solid red;
  }
  .back_border{
    background-color: #090619; border: 1px solid #475569;
  }
  .fs-14{
    font-size: 18px;
  }

  .box_height{
    height: 27rem;
  }
  .box_height1{
    height: 30rem;
  }
  .bold_text{
    color: #0168b5;
  }
  .bg_img{
    background-image: url(../assets/newimg/Maskgroup.png); background-size:contain; background-repeat: no-repeat; background-position: right; padding-top: 9rem; padding-bottom: 9rem;
  }
  .dot_style{
    width: 7px !important; height: 7px !important;
  }
  .gird_g{
    align-items: center;
    background: linear-gradient(to bottom right ,rgba(75,165,235,0),rgba(75,165,235,.08));
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;

    border-image: linear-gradient(180deg,rgba(37,47,71,0) 0%,#252f47) 1;
}
  .gird_g2{
    align-items: center;
    background: linear-gradient(to bottom left,rgba(75,165,235,0),rgba(75,165,235,.08));
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(180deg,rgba(37,47,71,0) 0%,#252f47) 1;
}
  .gird_g3{
    align-items: center;
    background: linear-gradient(to top right,rgba(75,165,235,0),rgba(75,165,235,.08));
    border-right: 2px solid transparent;
    border-image: linear-gradient(0deg,rgba(37,47,71,0) 0%,#252f47) 1;
}
  .gird_g4{
    align-items: center;
    background: linear-gradient(to top left,rgba(75,165,235,0),rgba(75,165,235,.08));
    /* border-right: 2px solid transparent; */
    border-image: linear-gradient(0deg,rgba(37,47,71,0) 0%,#252f47) 1;
}
.body_background{
    background-image: url(../assets/newimg/body_background.png); background-size:cover; background-repeat: no-repeat; background-position: center;
}
.accordion_style{
    background-color: transparent !important;
    border-style: none;
    box-shadow: none !important;
    font-size: 1.5rem !important;
    line-height: 1.334;
    letter-spacing: 0em;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-weight: 600;
}
.accordion-button {
  --bs-accordion-btn-color: rgb(255, 255, 255);
  color: var(--bs-accordion-btn-color);
}
.footer_background{
    background-image: url(../assets/newimg/footer_background.png); background-size:cover; background-repeat: no-repeat; background-position: center;
}
.footer_boder{
    border: 1px solid white;
    width: fit-content;
}
.box_bottm_border{
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
}
.virticslly_text{
  height: 15rem; display: flex; flex-direction: column; justify-content: space-between;
}
.virticslly_text1{
  height: 20rem; display: flex; flex-direction: column; justify-content: space-between;
  border: 1px solid #4ba5eb;
}
.nav_simple{
  color: white !important;
}


/* slider-css */
.slick-prev {
    background-color: transparent !important;
}
.slick-prev:before {
    top: 125px !important;
    position: absolute !important;
    left: 34.5rem !important;
    /* border: 1px solid white !important; */
    border-radius: 50% !important;
}
.slick-next:before {
    content: '→';
    position: absolute !important;
    top: 125px !important;
    right: 34.5rem !important;
}

.button_leniar{
    background-image: linear-gradient(to right, #0168B5 , #4BA5EB);
    border-style: none;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}
.sign_in_style{
    border-image: linear-gradient(to top, #4BA5EB, #0168B5) 1;
    border-width: 1px;
    background-color: transparent;
    /* border-style: none; */
    height: 40px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}
.p_icon{
  height: 60px;
  width: 60px;
  border-radius: 60px;
}
.text_sty{
  font-size: 24px;
  font-weight: 700;
}


/* .accordion-button{
  filter: brightness(1);
} */

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.fs-12{
  font-size: 12px;
}
.footer_dec li a{
  text-decoration: none;
  color: white;
  font-size: 14px;
}

/* slider-indicaters */
/* .slick-dots li.slick-active button:before {
    opacity: .75;
    color: red;
    background-color: red;
    border-radius: 50%;
} */
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 29px !important;
    line-height: 20px !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #4BA5EB !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px 1px;
  padding: 0;
  cursor: pointer;
}

.img_width{
  width: 2rem;
}

.img_width-2{
  width: 3rem;
}

.fw_samibold{
  color: #efedfd;
  font-size: 18px;
  font-weight: 500;
}

.f_heading{
  font-size: 24px;
  font-weight: 700;
  color: #f4f0ff;
}
.f_heading2{
  font-size: 24px;
  font-weight: 500;
  color: #f4f0ff;
}
.main_heading{
font-size: 32px;
 font-weight: 700;
color: #4ba5eb;
}
.main_heading-4{
  font-size: 32px;
   font-weight: 700;
 
  -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    -webkit-background-clip: text!important;
    background: linear-gradient(180deg,#0168b5,#4ba5eb);
    background-clip: text;
    color: transparent;
    font-family: Plus Jakarta Sans,Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -.8px;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  
  }

.main_heading2{
font-size: 32px;
 font-weight: 700;
color: #f4f0ff;
}
.accordion-body{
  font-weight: 400;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.334;
  color:#f4f0ff ;
}
.accordion-button{
  color: white;
}

.border_resp{
  border: 1px solid #0168b5;
}
@media screen and (max-width: 767px) {
  .border_resp{
    border-style: none;
  }
}
@media screen and (max-width: 768px) {
  .fw_samibold{
    align-self: stretch;
      color: #efedfd;
      font-size: 18px;
      font-weight: 500;
  }
  .f_heading{
    font-size: 18px;
    font-weight: 700;
    color: #f4f0ff;
  }
  .f_heading2{
    font-size: 14px;
    font-weight: 500;
    color: #f4f0ff;
  }
  .main_heading{
  font-size: 24px;
   font-weight: 700;
  color: #4ba5eb;
  }
  .main_heading2{
  font-size: 18px;
   font-weight: 700;
  color: #f4f0ff;
  }
  .accordion-body{
    font-weight: 400;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.334;
    color:#f4f0ff ;
  }
  .box_height1{
    height: 22rem;
  }
  .accordion-body{
    font-size: 14px;
  }
  .accordion_style{
    font-size: 1rem !important;
    font-weight: 600;
}
.p_icon{
  height: 30px;
  width: 30px;
  border-radius: 30px;
}
}
@media screen and (max-width: 576px) {
  .box_height{
    height: auto;
  }
  .bg_img{
    padding-top: 3rem; padding-bottom: 3rem;
    background-position: left;
  }
  .virticslly_text{
    height: auto;
  }
  .virticslly_text1{
    height: auto;
  }
}

.testimonial-height{

  min-height: 250px;
}

.slick-prev {
  background-color: transparent !important;
}
.slick-prev:before {
  /* content: '←'; */
  top: 125px !important;
  position: absolute !important;
  left: 34.5rem !important;
  /* border: 1px solid white !important; */
  border-radius: 50% !important;
}
.slick-next:before {
  content: '→';
  position: absolute !important;
  top: 125px !important;
  right: 34.5rem !important;
}

.landing-page-revised .code-genie-AI-audits {
  flex: 0 0 auto;
  position: relative;
  width: 344.9px;
}

.footer_boder{
  border: 1px solid white;
  width: fit-content;
}
.box_bottm_border{
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
}

.css-1ylu0bo {
  display: flex;
  flex-direction: column;
  -moz-box-flex: 1;
  flex-grow: 1;
  margin-top: 0px !important;

}

.css-1y5sho6{
  border: 1px solid #475569;
  border-radius: var(--bs-border-radius-sm)!important;
  margin-bottom: 0.5rem;
  padding: 5px ;


}

.widthfull{
  width : 100%;
}

.responsive-margin{
  margin-bottom: 35px;
}

p {
  text-align: justify;
}
.twitter-padding{

padding-left: 5px;

}

.testimonial-height{

  min-height: 250px;
}

.R1 {
 width: 51%;
  
}
.footer-logo{
margin-left: -15px;

}

.landing-page-revised .icon-instance-node-4 {
  height: 24px !important;
  left: 18px !important;
  position: absolute !important;
  top: 18px !important;
  width: 24px !important;
}

.landing-page-revised .small-icons-in-hero {
  height: 577px;
  left: 686px;
  position: absolute;
  top: 112px;
  width: 648px;
}

.frame-333 {
  align-items: center;
  background: rgb(21, 91, 171) ;
  display: flex;
  gap: 8px;
  height: auto;
  justify-content: center;
  padding: -3px 16px;
  position: relative;
  width: 70%;
  margin-top: 10px;
  margin-bottom: -25px;
  font-size: x-large;
  margin: auto;
}

.never{

  text-align: center;
}

.p {
  margin-top: -21px;
  margin-bottom: 1rem;
}

.body-1 {
  width: 80%;
  max-width: 100%;
  height: auto;
  margin-top: -80px;
}
.body-L1 { 
  width: 56%;
  max-width: 100%;
  height: auto;
  margin-top: 0px;
}

.body-2 {
  max-width: 100%;
  height: auto;
}

img, svg {
  vertical-align: middle;
}

.fw_samibold {
  align-self: stretch;
  color: #efedfd;
  font-size: 18px;
  font-weight: 500;
} 
.Never-1 {
  align-self: stretch;
  color: #efedfd;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
} 

.landing-page-revised .frame-75 {
  align-items: center;
  background: radial-gradient(50% 50% at 50% 50%, rgb(0, 6.5, 54.19) 0%, rgba(0, 3, 25, 0) 100%);
  display: flex;
  gap: 35.62px;
  height: 268px;
  justify-content: center;
  left: 0;
  padding: 96px 73px;
  position: absolute;
  top: 940px;
  width: 1440px;
}

.frame-33345 {
  align-items: center;
  background: #155bab ;
  display: flex;
  gap: 8px;
  height: auto;
  justify-content: center;
  padding: -3px 16px;
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: -25px;
  font-size: x-large;
  margin: auto;
  height: 4rem;
}

.frame-3334 {
  background: #155bab;
  font-size: x-large;
  margin-bottom: -38px;
  width: 461px;
  height: 3rem;
  padding-top: 5px;
  margin-left: -15px;
}

.backgroundbg{

  background-image: url(../assets/img/icon-bg.png);
  background-repeat: "no-repeat";
  background-position: "center";
  background-size: "cover";

}
.img-fluid-code{
  max-width: 100%;
  height: auto;
}

.frame-1707{
  margin-top: -84px;


  
}

.backgroundbg-do{
 background-image: url(../assets/img/icon-bg.png);
  display: flex;
  flex-wrap: wrap;

}

.smart-nav{
  align-items: center;
  background: rgb(248, 248, 248) ;
  display: flex;
  gap: 8px;
  height: auto;
  justify-content: center;
  padding: -3px 16px;
  position: relative;
  min-width: 150px;
  margin: auto;
}

.anycode-nav {
  align-items: center;
  background: rgb(34, 92, 215) ;
  background-color:  rgb(192 181 182);
  display: flex;
  gap: 8px;
  position: relative;
  min-width: 150px;
  word-spacing: 2px;
  margin: auto;
  justify-content: space-around;
}



.smart-nav-1{
  align-items: center;
  background: rgb(34, 92, 215) ;
  display: flex;
  gap: 8px;
  height: auto;
  justify-content: center;
  padding: -3px 16px;
  position: relative;
  min-width: 150px;
  margin: auto;
}

.anycode-nav-1 {
  align-items: center;
  background:  rgb(192 181 182);
  display: flex;
  gap: 8px;
  position: relative;
  min-width: 150px;
  word-spacing: 2px;
  margin: auto;
  justify-content: space-around;
}

/* .smart-nav-2{
  align-items: center;
  background: rgb(248, 248, 248) ;
  display: flex;
  gap: 8px;
  height: auto;
  justify-content: center;
  padding: -3px 16px;
  position: relative;
  min-width: 150px;
  margin: auto;
}

.anycode-nav-2 {
  align-items: center;
  background: rgb(248, 248, 248) ;
  display: flex;
  gap: 8px;
  position: relative;
  min-width: 150px;
  word-spacing: 2px;
  margin: auto;
  justify-content: space-around;
} */


.Sign-1 {
  align-self: stretch;
  color: #efedfd;
  font-size: 18px;
  font-weight: 500;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
  width: 509px
}  

.Sign-2{
  text-align: center;
}
.contact-2{
}
.sign-up{
  /*margin-right: -125px;
  width: 100%;
  */
  align-self: stretch;
  color: #efedfd;
  font-size: 18px;
  font-weight: 500;
  /*margin-left: 15%;
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;*/
  width: 100%

}
.img-fluid-code-L1{
  /*max-width: 100%;*/
  height: auto;
  margin-top: -28px;
  width: -webkit-fill-available;
}
.P1{

text-align: justify;
}


.padding-top-90{
  padding-top: 90px;
}

.padding-top-50{
  padding-top: 50px;
  
}

.signupbg{
  width: 100%;
}

.padding-top-30{
  padding-top: 30px;
}

.css-1tfsyk0-MuiGrid-root{
  padding-left: 0px !important;
}

.MuiBox-root.css-17lv2m {
  padding: 5px !important;
}

 .white-cell {
    background-color: white;
  }
  .black-text {
    color: black;
  }

  .red-border {
    border: 1px solid black;
  }

.black-border {
  border: 2px solid black;
}

.red-label {
  color: red;
}

.css-2cguwl{

  padding-left: 0px !important;
}

.How{

}

.adminhead{
background-color: #fff;
color: #090619;
  }


.SignupBackground{
  width: 100%;
}


@media screen and (max-width: 768px) {
  .Sign-1 {
    margin: 0px !important;
    width: 100% !important;
  }

  .sign-up {
    margin: 0px !important;
    width: 100% !important;
  }
}