.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px; /* Adjust margin as needed */
  }
  
  /* Style for the image */
  .ellipse-18 {
    max-width: 100%; /* Set the maximum width of the image to 100% */
    height: auto; /* Maintain aspect ratio */
  }

  @media only screen and (max-width: 480px) {
    img {
      max-width: 200px;
      height: auto;
    }
  }

  .text-primary1{
    color: #fff !important;
  }